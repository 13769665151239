import { graphql } from 'gatsby'
import React from 'react'
import { HeadProps } from '~src/components/Head'
import { HeaderPlaceHolder } from '~src/components/HeaderPlaceHolder'
import { Footer } from '~src/components/Footer'
import { Main } from '~src/units/Main'
import { FeaturesArticle, FeaturesSection } from '~src/units/Article'

export interface HabitsProps extends HeadProps {}

const HabitsPage: React.FC<HabitsProps> = ({ data }) => (
  <Main>
    <HeaderPlaceHolder />
    <FeaturesSection>
      <FeaturesArticle
        dangerouslySetInnerHTML={{ __html: data.markdownRemark!.html }}
      />
    </FeaturesSection>
    <Footer />
  </Main>
)

export default HabitsPage

export const pageQuery = graphql`
  query HabitsMDQuery {
    markdownRemark(frontmatter: { slug: { eq: "Habits" } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
